import type { definitions } from '~/types/supabase'
import type { UserModule } from '~/types'
import { useMainStore } from '~/stores/main'
import { useSupabase } from '~/services/supabase'

const guard = async (next: any, to: string, from: string) => {
  const supabase = useSupabase()
  let auth = supabase.auth.user()
  // console.log('auth', auth)
  const main = useMainStore()

  if (auth && !main.auth) {
    main.auth = auth
    // console.log('auth', auth)
    main.pin = auth.user_metadata?.pin
    const today = new Date()
    const lastSignInAt = new Date(Date.parse(auth.last_sign_in_at || ''))
    lastSignInAt.setDate(lastSignInAt.getDate() + 30)
    if (!main.user) {
      try {
        const { data, error } = await supabase
          .from<definitions['users']>('users')
          .select(`
        id,
        city,
        country,
        role,
        image_url,
        first_name,
        enableNotifications,
        optForNewsletters,
        legalAccepted,
        last_name,
        image_url
      `)
          .match({ id: auth?.id })
        if (!error && data && data.length) { main.user = data[0] }
        else {
          await main.logout()
          main.displayCode = false
          next('/login')
        }
      }
      catch (error) {
        console.log('error', error)
      }
    }

    if (today > lastSignInAt) {
      try {
        await supabase.auth.update({
          data: {
            isPhoneVerified: false,
          },
        })
      }
      catch (error) {
        console.log('error', error)
      }

      auth = supabase.auth.user() || auth
      main.auth = auth
    }
    if (main.professional === null && main.user) {
      if (main.user.role === 'doctor' || main.user.role === 'default')
        main.professional = true
      else
        main.professional = false
    }
    if (main.professional && auth.user_metadata?.needPasswordChange && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/first_password')
    else if (main.professional && !auth.user_metadata?.activation?.formFilled && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/activation')
    else if (main.professional && !auth.user_metadata?.pin && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/create_code')
    else if (main.professional)
      next()
    else if (!auth.user_metadata?.isPhoneVerified && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/phone_code')
    else if ((!auth.user_metadata?.activation?.formFilled || !main.user?.legalAccepted) && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/activation')
    else if (!main.pin && !to.includes('/onboarding') && !from.includes('/onboarding'))
      next('/onboarding/create_code')
    else next()
  }
  else if (from !== 'login' && !auth) {
    main.auth = null
    next('/login')
  }
  else { next() }
}

// // vueuse/head https://github.com/vueuse/head
export const install: UserModule = ({ router }) => {
  router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware)
      await guard(next, to.path, from.path)
    else
      next()
  })
}
